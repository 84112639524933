import './App.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Register from './components/Register';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import EmailActivation from './components/EmailActivation';
import Logout from './components/Logout';
import Home from './components/Home';
import CIS_Module from './components/CIS_Module';
import ConnectDevices from './Device_CMD';
import DashboardLayout from './components/Dashboard_layout';
import Profile from './components/Profile';
import ThemeProvider from './theme';
import AlertsTab from './components/AlertsTab';
import Connecters from './components/Connecters';
import Syslog from './components/Syslog';
import Cloud from './components/Cloud';
import CloudAccount from './components/CloudAccount';
import UpdateCloud from './components/CloudForms/UpdateCloud';
import Reporting from './components/Reporting';
import ResetConfirmPassword from './components/ResetConfirmPassword';
import Modules from './components/Modules';
import Reports_Dashboard from './components/Reports_Dashboard';
import ManageUsers from './components/ManageUsers';
import Newdashboard from './components/NewDashboard';
import ChatBot from './components/ChatBot';
import DataSourceBarGraph from './components/GodigitDashboards/DataSourceBarGraph';
import Discover from './components/Discover';
import Experiment from './components/Experiment';
import NewLogin from './components/NewLogin';
import NewRegister from './components/NewRegister';
import Troubleshoot from './components/Troubleshoot';
import Generate_Report from './components/Generate_Report';
import CustomReports from './components/CustomReports';
import Custom_Reports from './components/Custom_Reports';
import DataManagement from './components/DataManagement';

function App() {
  return (
<ThemeProvider>
    <Router>
      <Routes>
        <Route path="/" element={<NewLogin />} />
        <Route path="/newlogin" element={<NewRegister />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/auth/reset-pwd/confirm/" element={<ResetConfirmPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/email-confirmation/:token" element={<EmailActivation />} />
        <Route path="/troubleshoot" element={<Troubleshoot />} />
        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route path="home" element={<Home />} />
          <Route path="connectors" element={<Connecters />} />
          <Route path="connect_devices" element={<ConnectDevices />} />
          <Route path="cis" element={<CIS_Module />} />
          <Route path="reporting" element={<Reporting />} />
          {/* <Route path="customreports" element={<CustomReports />} /> */}
          <Route path="configure_reports" element={<Custom_Reports />} />
          <Route path="generate_reports" element={<Generate_Report />} />
          <Route path="reports_dashboard" element={<Reports_Dashboard />} />
          <Route path="alerts" element={<AlertsTab />} />
          <Route path="data_management" element={<DataManagement />} />
          <Route path="manageusers" element={<ManageUsers />} />
          <Route path="datainsights" element={<Newdashboard />} />
          <Route path="logsearch" element={<Discover />} />
          <Route path="chatbot" element={<ChatBot />} />
          <Route path="godashboards" element={<DataSourceBarGraph />} />
          <Route path="mlsearch" element={<Experiment />} />
          <Route path="alerts" element={<AlertsTab />} />
          <Route path="profile" element={<Profile />} />
          <Route path="syslogdevices" element={<Syslog />} />
          <Route path="cloud" element={<Cloud />} />
          <Route path="add-cloud-account" element={<CloudAccount />} />
          <Route path="/dashboard/update-cloud-account/" element={<UpdateCloud />} />
          <Route path="/dashboard/modules/security-management/security-event" element={<Modules />} />
          <Route path="/dashboard/modules/security-management/integrity-monitoring" element={<Modules />} />
          <Route path="/dashboard/modules/auditing-policy-monitoring/policy-monitoring" element={<Modules />} />
          <Route path="/dashboard/modules/auditing-policy-monitoring/system-auditing" element={<Modules />} />
          <Route path="/dashboard/modules/auditing-policy-monitoring/security-configuration" element={<Modules />} />
          <Route path="/dashboard/modules/threat-detection/vulnerabilities" element={<Modules />} />
          <Route path="/dashboard/modules/threat-detection/mitre-attack" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/pci-dss" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/nist-800-53" element={<Modules />} />
          {/* <Route path="/dashboard/modules/regulatory-compliance/tsc" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/gdpr" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/hipaa" element={<Modules />} /> */}
        </Route>
      </Routes>
    </Router>
  </ThemeProvider>
  );
}

export default App;
