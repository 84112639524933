import React, { useState, useEffect } from 'react';

const NewDashboard = () => {
    const [tenantname, setTenantname] = useState(null);
    const [kibanaToken, setKibanaToken] = useState(null);

    useEffect(() => {
        const user_details = JSON.parse(window.localStorage.getItem("user_details"));
        const tenantname = JSON.parse(window.localStorage.getItem("tenant_name"));
        const kibanaToken = user_details?.data?.kibana_token;

        if (tenantname && kibanaToken) {
            setTenantname(tenantname);
            setKibanaToken(kibanaToken);
        }
    }, []);

    return (
        <>
            {tenantname && kibanaToken && (
                <iframe
                    src={`https://saas.khika.in/kibana/app/dashboards/wazuh?security_tenant=${tenantname}&auth_token=${kibanaToken}#/view/25e64e80-49ea-11ef-94a5-9d045b0c62c4?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Godigit-DB1',viewMode:view)&embed=true&show-time-filter=true`}
                    width="100%"
                    height="800px"
                    title="Dashboard"
                    frameBorder="0"
                ></iframe>
            )}
        </>
    );
};

export default NewDashboard;