import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend,
  LineChart, Line,
} from 'recharts';
import { Grid, Box, Typography, TextField, MenuItem } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { BaseUrl } from '../../constants/constants';
import { DataGrid } from '@mui/x-data-grid';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28AD8', '#D0ED57', '#FF4554', '#82CA9D', '#A4DE6C', '#8884D8', '#F48C37', '#D0DD57', '#A19EDB', '#FF4054', '#82CA7D', '#A4DE4C', '#8885D8', '#A28ADD', '#D0FD57', '#FF4354', '#82CB9D', '#A4DD6C', '#8882D8', '#A28ACD', '#D0EE57', '#FF8754', '#82CA2D', '#A4DF6C', '#8882D8'];

const DataSourceBarGraph = () => {
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;

  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [fromTs, setFromTs] = useState(dayjs().subtract(2, 'month'));
  const [toTs, setToTs] = useState(dayjs());
  const [collectorTypes, setCollectorTypes] = useState([]);
  const [collectorEventNames, setCollectorEventNames] = useState([]);
  const [srcUsernames, setSrcUsernames] = useState([]);
  const [productHostnames, setProductHostnames] = useState([]);
  const [selectedCollectorType, setSelectedCollectorType] = useState('All');
  const [selectedCollectorEventName, setSelectedCollectorEventName] = useState('All');
  const [selectedSrcUsername, setSelectedSrcUsername] = useState('All');
  const [selectedProductHostname, setSelectedProductHostname] = useState('All');

  const [mlFilter, setMlFilter] = useState('all');

  const handleMlFilterChange = (event) => {
    setMlFilter(event.target.value);
  };

  const [dataGridRows, setDataGridRows] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(dataGridRows);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchText(searchValue);

    if (searchValue === '') {
      setFilteredRows(dataGridRows);
    } else {
      const filteredData = dataGridRows.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchValue)
        )
      );
      setFilteredRows(filteredData);
    }
  };

  // const fetchData = async (fromTs, toTs, filters = {}, mlFilter = 'all') => {
  //   const data = JSON.stringify({
  //     "fromTS": fromTs.toISOString(),
  //     "toTS": toTs.toISOString(),
  //     "filters": filters,
  //     "ml_filter": mlFilter
  //   });
  
  //   const config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: BaseUrl + '/user/dashboard_analysis',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     },
  //     data: data
  //   };
  
  //   try {
  //     const response = await axios.request(config);
  //     const { data_src, data_evt_share, data_src_by_time } = response.data;
  
  //     const barChartData = data_src.aggregations["data.collectorType"].buckets;
  //     const pieChartData = data_evt_share.aggregations["data.collectorEventName"].buckets
  //       .sort((a, b) => b.doc_count - a.doc_count)
  //       .slice(0, 10);
  
  //     const lineChartData = [];
  //     data_src_by_time.aggregations["data.collectorType"].buckets.forEach((bucket) => {
  //       bucket.eventTime.buckets.forEach((timeBucket) => {
  //         lineChartData.push({
  //           name: bucket.key,
  //           time: dayjs(timeBucket.key).format('YYYY-MM-DD HH:mm'),
  //           count: timeBucket.doc_count
  //         });
  //       });
  //     });
  
  //     setBarChartData(barChartData);
  //     setPieChartData(pieChartData);
  //     setLineChartData(lineChartData);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };   

  const fetchData = async (fromTs, toTs, filters = {}, mlFilter = 'all') => {
    const data = JSON.stringify({
      "fromTS": fromTs.toISOString(),
      "toTS": toTs.toISOString(),
      "filters": filters,
      "ml_filter": mlFilter
    });
  
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/user/dashboard_analysis',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
  
    try {
      const response = await axios.request(config);
      const { data_src, data_evt_share, data_src_by_time, data_username_events } = response.data;
  
      const barChartData = data_src.aggregations["data.collectorType"].buckets;
      const pieChartData = data_evt_share.aggregations["data.collectorEventName"].buckets
        .sort((a, b) => b.doc_count - a.doc_count)
        .slice(0, 10);
  
      const lineChartData = [];
      data_src_by_time.aggregations["data.collectorType"].buckets.forEach((bucket) => {
        bucket.eventTime.buckets.forEach((timeBucket) => {
          lineChartData.push({
            name: bucket.key,
            time: dayjs(timeBucket.key).format('YYYY-MM-DD HH:mm'),
            count: timeBucket.doc_count
          });
        });
      });
  
      const dataGridRows = data_username_events.aggregations['data.collectorType'].buckets.flatMap((collectorTypeBucket, collectorIndex) => {
        return collectorTypeBucket['data.srcUsername'].buckets.flatMap((usernameBucket, usernameIndex) => {
          return usernameBucket['data.collectorEventName'].buckets.map(eventBucket => ({
            id: `${collectorIndex}-${usernameIndex}-${eventBucket.key}`,
            username: usernameBucket.key,
            collector_type: collectorTypeBucket.key,
            event: eventBucket.key,
            count: eventBucket.doc_count,
            eventTime: eventBucket.first_evt_ts.hits.hits[0]._source.data.eventTime
          }));
        });
      });

      setBarChartData(barChartData);
      setPieChartData(pieChartData);
      setLineChartData(lineChartData);
      setDataGridRows(dataGridRows);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const fetchDropdownData = async () => {
    const data = JSON.stringify({
      "fromTS": fromTs.toISOString(),
      "toTS": toTs.toISOString(),
      "field_list": [
        "data.collectorType",
        "data.collectorEventName",
        "data.srcUsername",
        "data.productHostname"
      ]
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/user/filters_da',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      const { filters_data } = response.data;

      setCollectorTypes(['All', ...filters_data["data.collectorType"]]);
      setCollectorEventNames(['All', ...filters_data["data.collectorEventName"]]);
      setSrcUsernames(['All', ...filters_data["data.srcUsername"]]);
      setProductHostnames(['All', ...filters_data["data.productHostname"]]);
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  useEffect(() => {
    fetchData(fromTs, toTs);
    fetchDropdownData();
  }, []);

  useEffect(() => {
    const filters = {};
    if (selectedCollectorType !== 'All') {
      filters["data.collectorType"] = selectedCollectorType;
    }
    if (selectedCollectorEventName !== 'All') {
      filters["data.collectorEventName"] = selectedCollectorEventName;
    }
    if (selectedSrcUsername !== 'All') {
      filters["data.srcUsername"] = selectedSrcUsername;
    }
    if (selectedProductHostname !== 'All') {
      filters["data.productHostname"] = selectedProductHostname;
    }
  
    fetchData(fromTs, toTs, filters, mlFilter);
  }, [fromTs, toTs, selectedCollectorType, selectedCollectorEventName, selectedSrcUsername, selectedProductHostname, mlFilter]);  

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { name, count } = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Date: ${label}`}</p>
          <p className="intro">{`Count: ${count}`}</p>
          <p className="desc">{`Key: ${name}`}</p>
        </div>
      );
    }
  
    return null;
  };
  
  const columns = [
    { field: 'collector_type', headerName: 'Collector SRC', flex: 0.7 },
    { field: 'username', headerName: 'Username', flex: 0.7 },
    { field: 'event', headerName: 'Event', flex: 1.5 },
    { field: 'count', headerName: 'Count', flex: 1, type: 'number' },
    { field: 'eventTime', headerName: 'First Occurrence', flex: 1, type: 'date' },
  ];
  
  return (
    <>
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box padding={2} border={1} borderColor="grey.300" borderRadius={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <DateTimePicker
                label="From"
                value={fromTs}
                onChange={(newValue) => setFromTs(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DateTimePicker
                label="To"
                value={toTs}
                onChange={(newValue) => setToTs(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                select
                label="Hostname"
                value={selectedProductHostname}
                onChange={(e) => setSelectedProductHostname(e.target.value)}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        maxWidth: 200,
                        overflowY: 'auto',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                      },
                    },
                  },
                }}
              >
                {productHostnames.map((hostname, index) => (
                  <MenuItem key={index} value={hostname} sx={{ whiteSpace: 'normal' }}>
                    {hostname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                select
                label="Username"
                value={selectedSrcUsername}
                onChange={(e) => setSelectedSrcUsername(e.target.value)}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        maxWidth: 200,
                        overflowY: 'auto',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                      },
                    },
                  },
                }}
              >
                {srcUsernames.map((username, index) => {
                  const parts = username.split('LOCAL\\');
                  return (
                    <MenuItem key={index} value={username} sx={{ whiteSpace: 'normal' }}>
                      {parts.length > 1 ? (
                        <Box>
                          <div>{parts[0]}LOCAL\</div>
                          <div>{parts[1]}</div>
                        </Box>
                      ) : (
                        username
                      )}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                select
                label="Collector Type"
                value={selectedCollectorType}
                onChange={(e) => setSelectedCollectorType(e.target.value)}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        overflowY: 'auto',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                      },
                    },
                  },
                }}
              >
                {collectorTypes.map((type, index) => (
                  <MenuItem key={index} value={type} sx={{ whiteSpace: 'normal' }}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={2}>
              <TextField
                select
                label="Collector Event Name"
                value={selectedCollectorEventName}
                onChange={(e) => setSelectedCollectorEventName(e.target.value)}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        maxWidth: 200,
                        overflowY: 'auto',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                      },
                    },
                  },
                }}
              >
                {collectorEventNames.map((eventName, index) => (
                  <MenuItem key={index} value={eventName} sx={{ whiteSpace: 'normal' }}>
                    {eventName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={12} sm={2}>
            <TextField
              select
              label="ML Search"
              value={mlFilter}
              onChange={handleMlFilterChange}
              fullWidth
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      maxWidth: 200,
                      overflowY: 'auto',
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                    },
                  },
                },
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="infra change">Infra Change</MenuItem>
            </TextField>
          </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
            <Typography variant="h6" gutterBottom>
              Data Source By Time
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={lineChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
            <Typography variant="h6" gutterBottom>
              Data Source
            </Typography>
            <ResponsiveContainer width="100%" height={500}>
              <BarChart data={barChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="doc_count" fill="#00a69b" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
            <Typography variant="h6" gutterBottom>
              Top 10 Events Share
            </Typography>
            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  dataKey="doc_count"
                  nameKey="key"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  fill="#8884d8"
                  label
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item xs={12}>
       
          <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>  
          <Typography variant="h6">User Wise Events Details</Typography>

            <TextField
          label="Search"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchText}
          onChange={handleSearch}
        />
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={searchText === ''?dataGridRows:filteredRows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DataSourceBarGraph;