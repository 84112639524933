import React,{useState} from 'react'
import Navbar from './Navbar'
import axios, * as others from 'axios';
import { BaseUrl, DevicesList, TenantDetails, VulnerabilitiesDashboardId } from '../constants/constants';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Container } from '@mui/system';
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import Skeleton from '@mui/material/Skeleton';
const Home = () => {
   
  const [tenantname, setTenantName] = useState(JSON.parse(window.localStorage.getItem("tenant_name")))
  const [loading, setLoading] = useState(true)
  const [agents, setAgents] = useState([]);
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken
  const kibanaToken = user_details.data.kibana_token
  
  const ingested_data = async () => {
    var config = {
      method: 'get',
      url: BaseUrl + TenantDetails,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
  
    axios(config)
    .then(function (response) {
      window.localStorage.setItem("tenant_details", JSON.stringify(response.data));
      window.localStorage.setItem("tenant_name", JSON.stringify(response.data.tenant_name));
      setTenantName(response.data.tenant_name)
      devices_list()
    })
    .catch(function (error) {
      
    });
  }
  
  const devices_list = async () => {
    var config = {
      method: 'get',
      url: BaseUrl + DevicesList,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
  
    axios(config)
    .then(function (response) {
      setAgents(response.data.data)
      setLoading(false)
    })
    .catch(function (error) {
      
    });
  }

  const fetchUserRole = async () => {
    var config = {
      method: 'get',
      url: BaseUrl + '/user/',
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };

    axios.request(config)
    .then((response) => {
      const role = response.data.authentication.role;
      window.localStorage.setItem("user_role", role);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  useEffect(() => {
    ingested_data();
    fetchUserRole();
  }, []);

  const url = "https://saas.khika.in/kibana/app/wazuh?security_tenant=" + tenantname + "&embed=true&auth_token=" + kibanaToken +"#/overview/?tabView=panels&tab=welcome"
  
  const data = {
    datasets: [{
      label: 'Device Counts',
      data: [1,2,3],
      backgroundColor: [
        '#32CD32',
        'red',
        '#9370DB'
      ],
      hoverOffset: 4
    }]
  };
  
  return (
    <>
    <PerfectScrollbar>
        
    {loading ? <> <Box >
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box></> : 
    agents.total_affected_items>0?
    <>
    <iframe 
      className='hide_menu'
      frameBorder="0"
      src={"https://saas.khika.in/kibana/app/wazuh?security_tenant="+tenantname+"&embed=true&auth_token="+kibanaToken+"#/overview/?tabView=panels&tab=welcome"}
      /></>: <Paper sx={{m:'auto',maxWidth:400,textAlign:'center'}}>  <Typography variant="h5" >
        Please <Link to="/dashboard/connect_devices" style={{  color: 'black', textTransform: 'capitalize'}}  >Add/Connect</Link> devices 
        </Typography></Paper> }
    </PerfectScrollbar>
    </>
  )
}

export default Home