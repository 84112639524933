import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Container } from '@mui/system'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AwsForm from './CloudForms/AwsForm'
import AzureForm from './CloudForms/AzureForm'

const CloudAccount = () => {
    const [selectedValue, setSelectedValue] = useState('option1');

    const handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
    };
    
  return (
    <>
    <Container>
        <Button variant='outlined' className='syslog-back-btn'><Link to="/dashboard/cloud" style={{ textDecoration: 'none', color: '#00AB55', textTransform: 'capitalize'}}>Back</Link></Button>
        <RadioGroup value={selectedValue} onChange={handleRadioChange}
        style={{ display: 'flex', flexDirection: 'row' }}
        >
        <FormControlLabel
          value="option1"
          control={<Radio />}
          label="AWS - CLOUDTRAIL"
          style={{ marginRight: 30 }}
        />
        <FormControlLabel
          value="option2"
          control={<Radio />}
          label="AZURE - LOG ANALYTICS"
        />
      </RadioGroup>
      {selectedValue === 'option1' ? <AwsForm /> : <AzureForm />}
    </Container>
    </>
  )
}

export default CloudAccount