import React from 'react'

const Logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = '/';
  return (
    <></>
  )
}

export default Logout