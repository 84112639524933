import React, { useState } from 'react'
import { Box,Paper, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import swal from 'sweetalert';
import { BaseUrl, SignUpEndpoint } from '../constants/constants';
import { Link as RouterLink } from "react-router-dom"
import axios, * as others from 'axios';
import useFullPageLoader from './hooks/useFullPageLoader';
import Logo from './Logo';
import { motion } from "framer-motion";
import styled from "@emotion/styled";
const theme = createTheme();

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};
const HeadingStyle = styled(Box)({
  textAlign: "center",
});
export default function Register() {
  
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const url = BaseUrl + SignUpEndpoint;

  const onSubmit = data => {
    if (agreeToTerms){
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email))
    {
      const passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/
      if(data.password1.match(passw)){
        const pass1 = data.password1;
        const pass2 = data.password2;
        if(pass1 == pass2){
          showLoader();
          axios.post(url,{
            email_address: data.email,
            password: data.password1,
            first_name: data.first_name,
            last_name: data.last_name,
            company_name: data.company_name,
            phone: data.phone
          })
          .then(res=>{
            hideLoader();
            swal({
              title: "Congratulations! You are successfully registered with us!",
              text: "Please check your inbox for activation email.",
              icon: "success",
            }).then(function() {
              window.location = "/";
            });
          })
          .catch(function (error) {
            hideLoader();
            swal({
                title: error.response.data.message,
                text: "",
                icon: "error",
            })
          });
          return (true)
        }else{
          swal("Confirm Password does not match", " ", "error");
          return (false)
        }
      }else{
        swal("Password must contain characters between 6-12 with atleast one digit and one special character", " ", "error");
        return (false)
      }
    }
    swal("Please provide a valid email address!", " ", "error");
    return (false)
  }else{
      alert('Please agree to the Privacy Policy and License Terms & Conditions to log in.');
      hideLoader();
  }
  };

  return (
    <>
    <Container>
  
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper
          elevation={5}
          sx={{
            mt:2,
            padding:4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
           <HeadingStyle component={motion.div} {...fadeInUp}>
            <Logo/>
            <Typography component="h1" variant="h5">
           Enter your details below.
            </Typography>
            </HeadingStyle>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="first_name"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  autoFocus
                  {...register("first_name", { required: true })}
                />
                {errors.first_name?.type === 'required' && "First Name is required"}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="family-name"
                  {...register("last_name", { required: true })}
                />
                {errors.last_name?.type === 'required' && "Last Name is required"}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  required
                  fullWidth
                  id="company_name"
                  label="Company Name"
                  name="company_name"
                  autoComplete="family-name"
                  {...register("company_name", { required: true })}
                />
                {errors.company_name?.type === 'required' && "Company Name is required"}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="family-name"
                  {...register("phone", { required: true })}
                />
                {errors.phone?.type === 'required' && "Phone Number is required"}
              </Grid>
              <Grid item sm={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  {...register("email", { required: true })}
                />
                {errors.email?.type === 'required' && "Email is required"}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  required
                  fullWidth
                  name="password1"
                  label="Password"
                  type="password"
                  id="password1"
                  autoComplete="new-password"
                  {...register("password1", { required: true })}
                />
                {errors.password1?.type === 'required' && "Password is required"}
              </Grid>
              <Grid item sm={6}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="Confirm Password"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                  {...register("password2", { required: true })}
                />
                {errors.password2?.type === 'required' && "Password is required"}
              </Grid>
            </Grid>
            <br />
            <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    By logging in, you agree to the <a href="/Khika-Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/Khika-License-Terms-Conditions 1.pdf" target="_blank" rel="noopener noreferrer">License Terms & Conditions</a>
                  </span>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Have an account?{" "}
            <Link variant="subtitle2" component={RouterLink} to="/" style={{  color: 'black'}}>
              Login
            </Link>
          </Typography>
               
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
      {loader}
  
    </Container>
    </>
  );
}