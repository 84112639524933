import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AppWidgetSummary from './AppWidgetSummary'
import SyslogToggle from './ConnectorsToggle/SyslogToggle';
import WorkstationToggle from './ConnectorsToggle/WorkstationToggle';
import axios from 'axios';
import { BaseUrl, ConnectorsDetails } from '../constants/constants';
import useFullPageLoader from './hooks/useFullPageLoader';
import swal from 'sweetalert';
import CloudToggle from './ConnectorsToggle/CloudToggle';

const Connecters = () => {

    const user_role = window.localStorage.getItem("user_role");

    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const [workstationstatus, setWorkstationStatus] = useState();
    const [syslogstatus, setSyslogStatus] = useState();
    const [cloudstatus, setCloudStatus] = useState();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken

    const connectors = async () => {
        var config = {
            method: 'get',
            url: BaseUrl + ConnectorsDetails,
            headers: { 
                'Authorization': `Bearer ${token}`,
            }
          };
          
          axios(config)
          .then(function (response) {
            setWorkstationStatus(JSON.stringify(response.data.workstation.is_active));
            setSyslogStatus(JSON.stringify(response.data.syslog.is_active));
            setCloudStatus(JSON.stringify(response.data.cloud.is_active));
          })
          .catch(function (error) {
            
          });
      }
    
      useEffect(() => {
        connectors();
      }, []);

      function deployConnector(value) {
        showLoader();
        var config = {
            method: 'post',
            url: BaseUrl + ConnectorsDetails + value + '/deploy',
            headers: { 
                'Authorization': `Bearer ${token}`,
            }
        };
        
        axios(config)
        .then(function (response) {
            hideLoader();
            swal({
                title: "Connector Deployed Successfully",
                text: "",
                icon: "success",
              }).then(function() {
                window.location.reload();
            });
        })
        .catch(function (error) {
            
        });
        }

  return (
    <>
    <Container>
    <div className='deploy-btn'>
        <Button variant="outlined" onClick={handleOpen} disabled={user_role === 'user_ro'}>
            Deploy Connectors
        </Button>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Connector
          </Typography>
          <br />
          <Grid>
            <Grid container spacing={3} justify="center">
                <Grid item>
                    {workstationstatus ? <></> : <Button variant='outlined' onClick={() => deployConnector('workstation')}>WorkStation</Button>}
                </Grid>
                <Grid item>
                    {syslogstatus ? <></> : <Button variant='outlined' onClick={() => deployConnector('syslog')}>Syslog</Button>}
                </Grid>
                <Grid item>
                    {cloudstatus ? <></> : <Button variant='outlined' onClick={() => deployConnector('cloud')}>Cloud</Button>}
                </Grid>
            </Grid>
          </Grid>
        </Box>
        </Modal>
    </div>
    <div className='connectors'>
    <Grid container spacing={10}>
        {
            syslogstatus ?
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="SYSLOG" color="error" icon={'mdi:server'} />
                <br />
                <SyslogToggle />
            </Grid>
            :
            <></>
        }
        {
            workstationstatus ? 
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="WORKSTATION" color="warning" icon={'ant-design:windows-filled'} />
                <br />
                <WorkstationToggle />
            </Grid>
            :
            <></>
        }
        {
            cloudstatus ? 
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="CLOUD" color="info" icon={'mdi:microsoft-azure'} />
                <br />
                <CloudToggle />
            </Grid>
            :
            <></>
        }
    </Grid>
    </div>
    </Container>
    </>
  )
}

export default Connecters