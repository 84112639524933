import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import cronstrue from 'cronstrue';
import { BaseUrl } from '../constants/constants';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import TimezoneSelect from 'react-timezone-select';

const Custom_Reports = () => {
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [cronValue, setCronValue] = useState('');
  const [timezone, setTimezone] = useState(null);

  const [emails, setEmails] = useState('');

  useEffect(() => {
    const fetchReports = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseUrl + '/reports/',
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      };

      try {
        const response = await axios.request(config);
        setReports(response.data.list_report);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleOpenModal = (reportId) => {
    setSelectedReportId(reportId);
    setOpenModal(true);
  };

  const handleSettingOpenModal = (reportId) => {
    setSelectedReportId(reportId);
    setOpenSettingModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCronValue('');
    setTimezone(null);
  };

  const handleSettingCloseModal = () => {
    setOpenSettingModal(false);
    setCronValue('');
    setTimezone(null);
  };

  const handleSave = async () => {
    if (!selectedReportId || !cronValue || !timezone) {
      console.error('Missing report configuration details.');
      return;
    }

    const data = JSON.stringify({
      "khika_report_id": selectedReportId,
      "cron_val": cronValue,
      "time_zone": timezone.value
    });

    const config = {
      method: 'post',
      url: BaseUrl + '/reports/',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      handleCloseModal();
      const fetchReports = async () => {
        try {
          const response = await axios.get(BaseUrl + '/reports/', {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          setReports(response.data.list_report);
        } catch (error) {
          console.error(error);
        }
      };
      fetchReports();
    } catch (error) {
      console.error('Error saving report configuration:', error);
    }
  };

  const handleDeactivate = async (reportId) => {
    const data = JSON.stringify({
      "khika_report_id": reportId,
      "status": false
    });

    const config = {
      method: 'patch',
      url: BaseUrl + '/reports/status',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      const fetchReports = async () => {
        try {
          const response = await axios.get(BaseUrl + '/reports/', {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          setReports(response.data.list_report);
        } catch (error) {
          console.error(error);
        }
      };
      fetchReports();
    } catch (error) {
      console.error('Error deactivating report:', error);
    }
  };

  const handleActivate = async (reportId) => {
    const data = JSON.stringify({
      "khika_report_id": reportId,
      "status": true
    });

    const config = {
      method: 'patch',
      url: BaseUrl + '/reports/status',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      const fetchReports = async () => {
        try {
          const response = await axios.get(BaseUrl + '/reports/', {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          setReports(response.data.list_report);
        } catch (error) {
          console.error(error);
        }
      };
      fetchReports();
    } catch (error) {
      console.error('Error activating report:', error);
    }
  };

  const handleConfig = (reportId) => {
    handleOpenModal(reportId);
  };

  const handleSettingConfig = (reportId) => {
    handleSettingOpenModal(reportId);
  };

  const handleSetting = async (reportId) => {
    if (!selectedReportId || !cronValue || !timezone || !emails) {
      console.error('Missing report configuration details.');
      return;
    }
  
    const data = JSON.stringify({
      "khika_report_id": selectedReportId,
      "cron_val": cronValue,
      "time_zone": timezone.value,
      "emails": emails.split(',').map(email => email.trim())
    });
  
    const config = {
      method: 'patch',
      url: BaseUrl + '/reports/',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
  
    try {
      const response = await axios.request(config);
      handleCloseModal();
      const fetchReports = async () => {
        try {
          const response = await axios.get(BaseUrl + '/reports/status', {
            headers: { 'Authorization': `Bearer ${token}` }
          });
        } catch (error) {
          console.error(error);
        }
      };
      fetchReports();
    } catch (error) {
      console.error('Error saving report configuration:', error);
    }
  };

  const columns = [
    { field: 'title', headerName: 'Title', flex: 2, cellClassName: 'word-wrap' },
    { 
      field: 'cron_val', 
      headerName: 'Cron Value', 
      flex: 1,
      cellClassName: 'word-wrap',
      renderCell: (params) => {
        try {
          return cronstrue.toString(params.value);
        } catch (e) {
          console.error('Invalid cron expression:', params.value);
          return params.value;
        }
      }
    },
    {
      field: 'next_run', headerName: 'Next Run', flex: 1, cellClassName: 'word-wrap',
      renderCell: (params) => {
        let formattedTimestamp=params.value;
        if (params.value!="Report Disabled" && params.value!='-'){
          const utcTimestamp = new Date(params.value);
          const targetTimezone = params.row.time_zone;
          const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
            timeZone: targetTimezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });
          formattedTimestamp = dateTimeFormatter.format(utcTimestamp) + " " + targetTimezone;
        }
        return <>{formattedTimestamp=='-'?"Scheduling":formattedTimestamp}</>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      cellClassName: 'word-wrap',
      renderCell: (params) => {
        if (params.row.status === true) {
          return 'Enabled';
        } else if (params.row.status === false) {
          return 'Disabled';
        } else {
          return 'Report Disabled';
        }
      }
    },
    {
      field: 'info',
      headerName: 'Info',
      flex: 0.5,
      cellClassName: 'word-wrap',
      renderCell: (params) => (
        <Tooltip title={params.row.desc || 'No description available'}>
          <InfoIcon color="primary" />
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'word-wrap',
      renderCell: (params) => {
        if (params.row.status === true) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleDeactivate(params.row.khika_report_id)}
              >
                Deactivate
              </Button>
              <IconButton
                size="small"
                onClick={() => handleSettingConfig(params.row.khika_report_id)}
              >
                <SettingsIcon />
              </IconButton>
            </div>
          );
        } else if (params.row.status === false) {
          return (
            <>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleActivate(params.row.khika_report_id)}
            >
              Activate
            </Button>
            <IconButton
                size="small"
                onClick={() => handleSettingConfig(params.row.khika_report_id)}
            >
                <SettingsIcon />
            </IconButton>
            </>
          );
        } else {
          return (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleConfig(params.row.khika_report_id)}
            >
              Report Config
            </Button>
          );
        }
      }
    },
  ];

  const dialogStyle = {
    width: '1000px',
    height: '500px'
  };

  return (
    <div style={{ height: '80vh', width: '100%', marginTop: '1%' }}>
      <DataGrid 
        rows={reports}
        columns={columns}
        pageSize={10}
        loading={loading}
        getRowId={(row) => row.khika_report_id || row.title}
      />

      <Dialog open={openModal} onClose={handleCloseModal} PaperProps={{ style: dialogStyle }}>
        <DialogTitle>Configure Report</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Cron Expression"
            type="text"
            fullWidth
            variant="outlined"
            value={cronValue}
            onChange={(e) => setCronValue(e.target.value)}
          />
          <TimezoneSelect
            value={timezone}
            onChange={(selectedTimezone) => setTimezone(selectedTimezone)}
            placeholder="Select Timezone"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSettingModal} onClose={handleSettingCloseModal} PaperProps={{ style: dialogStyle }}>
        <DialogTitle>Update Report</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Cron Expression"
            type="text"
            fullWidth
            variant="outlined"
            value={cronValue}
            onChange={(e) => setCronValue(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Comma-Separated Emails"
            type="text"
            fullWidth
            variant="outlined"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            helperText="Enter email addresses separated by commas"
          />
          <TimezoneSelect
            value={timezone}
            onChange={(selectedTimezone) => setTimezone(selectedTimezone)}
            placeholder="Select Timezone"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSettingCloseModal}>Cancel</Button>
          <Button onClick={handleSetting}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Custom_Reports;