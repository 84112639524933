import Switch from "react-switch";
import React, { useState } from 'react'
import { ActiveResponse, BaseUrl, ConnectorsDetails } from '../../constants/constants';
import axios from 'axios';
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const WorkstationToggle = () => {
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const user_role = window.localStorage.getItem("user_role");
    const token = user_details.data.accessToken
    
    const [checked, setChecked] = useState();
    const [description, setDescription] = useState();

    var config = {
        method: 'get',
        url: BaseUrl + ConnectorsDetails,
        headers: { 
            'Authorization': `Bearer ${token}`,
        }
      };
      
      axios(config)
      .then(function (response) {
        setChecked(response.data.workstation.is_active)
        setDescription(response.data.workstation.description)
      })
      .catch(function (error) {
        console.log(error);
      });

    const handleChange = nextChecked => {
        setChecked(nextChecked);
        try
        {
        var data = JSON.stringify({
            "is_active": !checked,
            "config_json": {}
          });
          
          var config = {
            method: 'post',
            url: 'https://saas.khika.in /api/connectors/workstation/update',
            headers: { 
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data : data
          };
          axios(config)
          .then(function (response) {
            setChecked(!checked);
          })
          .catch(function (error) {
            console.log(error);
          });
        }
        catch (error) {
             console.error(error);
            }
    };    

    return (
    <>
    <div>
        <label>
            <Switch
            onChange={handleChange}
            checked={checked}
            className="react-switch"
            disabled={user_role === 'user_ro'}
            />
        </label>
        <Typography>
            <div className="connectors-status">
                Connecter <span>{checked ? "active" : "disabled"}</span>
            </div>
        </Typography>
        <br />
        <Button disabled={user_role === 'user_ro'}><Link to="/dashboard/connect_devices" style={{ textDecoration: 'none', color: '#00AB55', textTransform: 'capitalize'}}>Manage Windows/Linux Agents</Link></Button>
    </div>
    </>
    )
}

export default WorkstationToggle