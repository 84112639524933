import React, { useEffect } from 'react';

const Reporting = () => {
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;
  const kibanaToken = user_details.data.kibana_token;
  const tenantname = JSON.parse(window.localStorage.getItem("tenant_name"));
  
  const iframeSrc = `https://saas.khika.in/kibana/app/wazuh?security_tenant=${tenantname}&embed=true&auth_token=${kibanaToken}#/manager/?tab=reporting`;

  useEffect(() => {
    console.log('Iframe src:', iframeSrc);
  }, [iframeSrc]);

  return (
    <>
      <iframe 
        className='hide_menu'
        frameBorder="0"
        src={iframeSrc}
      />
    </>
  );
};

export default Reporting;