import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { BaseUrl, SyslogDevices } from '../constants/constants';
import { useState } from 'react';
import useFullPageLoader from './hooks/useFullPageLoader';
import swal from 'sweetalert';

const Syslog = () => {

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const user_role = window.localStorage.getItem("user_role");
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState({});
    const [pageSize, setPageSize] = React.useState(10);
    const [loading, setLoading] = React.useState(false);
    const [totalRows, setTotalRows] = React.useState(0);

    const columns = [
        { field: 'ip', headerName: 'IP Address',  flex: 0.1 },
        { field: 'device_type', headerName: 'Device Type', flex: 0.2 },
        { field: 'status', headerName: 'Active/Inactive', flex: 0.2 },
        { field: 'actions', headerName: 'Actions', width: 400, renderCell: (params) => {
          return (
            <Button
              onClick={() => deleteDevice(params.row)}
              variant="outlined"
              disabled={user_role === 'user_ro'}
            >
              Delete
            </Button>
          );
        } }
    ];

    React.useEffect(() => {
		fetchData('');
		
	}, []);

    const fetchData = async page => {
        setLoading(true);
        
        var config = {
            method: 'get',
            url: BaseUrl + SyslogDevices + page,
            headers: { 
                'Authorization': `Bearer ${token}`,
            }
          };
          
          axios(config)
          .then(function (response) {
            setData(response.data.map(element => {
                    return {
                      "id" : element.dev_id,
                      "status" : element.is_active === true ? element.is_active = "active" : element.is_active = "inactive",
                      "ip":element.ip_address,
                      "device_type": element.device_tag
                    }
                  }))
                  setTotalRows(response.data.dev_id);
                  setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      const isValidIpAddress = (ipAddress) => {
        const ipAddressPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipAddressPattern.test(ipAddress);
      };

      const [deviceType, setDeviceType] = useState("");
      const [ipAddress, setIpAddress] = useState("");
      const [ipAddressError, setIpAddressError] = useState("");

      const handleDeviceTypeChange = (event) => {
        setDeviceType(event.target.value);
      };
    
      const handleIpAddressChange = (event) => {
        setIpAddress(event.target.value);
        setIpAddressError(null);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        if (!isValidIpAddress(ipAddress)) {
          setIpAddressError("Invalid IP address");
          alert("Invalid IP Address");
        } else {
          showLoader();
          var data = JSON.stringify({
            "ip_address": ipAddress,
            "device_tag": deviceType
          });
          
          var config = {
            method: 'post',
            url: BaseUrl + SyslogDevices,
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            hideLoader();
            swal({
                title: "Device Added Successfully",
                text: "",
                icon: "success",
              }).then(function() {
                window.location.reload();
            });
          })
          .catch(function (error) {
            hideLoader();
            swal({
                title: error.response.data.message,
                text: "",
                icon: "error",
              }).then(function() {
                window.location.reload();
            });
          });
        }
      };

      const deleteDevice = (row) => {
        showLoader();
        var config = {
          method: 'delete',
          url: BaseUrl + SyslogDevices + "/" + row.id,
          headers: { 
            'Authorization': `Bearer ${token}`,
          }
        };
        
        axios(config)
        .then(function (response) {
          hideLoader();
            swal({
                title: "Device Deleted Successfully",
                text: "",
                icon: "success",
              }).then(function() {
                window.location.reload();
            });
        })
        .catch(function (error) {
          console.log(error);
        });
      };
      
  return (
    <>
    <Container>
        <Button variant='outlined' className='syslog-back-btn'><Link to="/dashboard/connectors" style={{ textDecoration: 'none', color: '#00AB55', textTransform: 'capitalize'}}>Back</Link></Button>
        <Button variant='outlined' className='syslog-add-btn' onClick={handleOpen} disabled={user_role === 'user_ro'}>Add Device</Button>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Device
          </Typography>
          <br />
          <form onSubmit={handleSubmit}>
          <TextField
            id="device-type-label"
            label="Device Type"
            value={deviceType}
            onChange={handleDeviceTypeChange}
            style={{width: '300px'}}
            required
          />
          <br />
          <br />
          <TextField
            id="ip-address"
            label="IP Address"
            value={ipAddress}
            onChange={handleIpAddressChange}
            style={{width: '300px'}}
            required
          />
          <br />
          <br />
          <Button type="submit" variant="outlined" disabled={user_role === 'user_ro'}>
              Add
          </Button>

        </form>
        </Box>
        </Modal>
        <div className='sys-devices-table'>
        <Card>
        <Box  style={{ height: 450, width: '100%' }}>
        <DataGrid
        rows={data}
        rowCount={totalRows}
        loading={loading}
        rowsPerPageOptions={[10]}
        rowHeight={50}
        pagination
        page={page}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        columns={columns}
        />
        </Box>
        </Card>
        </div>
    </Container>
    </>
  )
}

export default Syslog