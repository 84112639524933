import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import axios from 'axios';
import { BaseUrl, CloudDevices } from '../../constants/constants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import swal from 'sweetalert';

const AzureForm = () => {

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [formData, setFormData] = useState({
    "cron": '',
    "application_id": '',
    "application_key": '',
    "service": 'log_analytics',
    "tenantdomain": '',
    "query": '',
    "time_offset": '',
    "workspace": '',
    "az_sub_id": ''
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    showLoader();
    var data = JSON.stringify({
      "cron": formData.cron,
      "application_id": formData.application_id,
      "application_key": formData.application_key,
      "service": "log_analytics",
      "tenantdomain": formData.tenantdomain,
      "query": formData.query,
      "time_offset": formData.time_offset,
      "workspace": formData.workspace,
      "az_sub_id": formData.az_sub_id
    });

    var config = {
      method: 'post',
      url: BaseUrl + CloudDevices + '/azure/log_analytics',
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      hideLoader();
      swal({
        title: "Account Created Successfully",
        text: "",
        icon: "success",
      }).then(function() {
        window.location.href = '/dashboard/cloud'
      });
    })
    .catch(function (error) {
      console.log(error);
    });

  };

  const validateApplicationId = (applicationId) => {
    const applicationIdRegex = /^[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){4}[0-9a-fA-F]{8}$/;
    return applicationIdRegex.test(applicationId);
  };

  const [showSecretKey, setShowSecretKey] = useState(false);

  const validateAzureSubscriptionId = (subscriptionId) => {
    const subscriptionIdRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
    return subscriptionIdRegex.test(subscriptionId);
  };

  return (
    <>
    <br />
    <br />
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <FormControl fullWidth>
            <InputLabel>Cron</InputLabel>
            <Select
              name="cron"
              value={formData.cron}
              onChange={handleChange}
              required
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="@once">@once</MenuItem>
              <MenuItem value="@hourly">@hourly</MenuItem>
              <MenuItem value="@daily">@daily</MenuItem>
              <MenuItem value="@weekly">@weekly</MenuItem>
              <MenuItem value="@monthly">@monthly</MenuItem>
              <MenuItem value="@yearly">@yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Application ID"
            name="application_id"
            value={formData.application_id}
            onChange={handleChange}
            required
            fullWidth
            error={formData.application_id && !validateApplicationId(formData.application_id)}
            helperText={
              formData.application_id && !validateApplicationId(formData.application_id)
                ? 'Invalid Azure application ID format'
                : ''
            }
          />
          <Typography variant="body2" color="textSecondary">
            Example: 12345678-9012-3456-7890-1234567890ab
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Application Key"
            name="application_key"
            value={formData.application_key}
            onChange={handleChange}
            required
            fullWidth
            type={showSecretKey ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowSecretKey(!showSecretKey)} edge="end" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Service"
            name="service"
            value="log_analytics"
            disabled
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Tenant Domain"
            name="tenantdomain"
            value={formData.tenantdomain}
            onChange={handleChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Query"
            name="query"
            value={formData.query}
            onChange={handleChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <FormControl fullWidth>
            <InputLabel>Time Offset</InputLabel>
            <Select
              name="time_offset"
              value={formData.time_offset}
              onChange={handleChange}
              required
            >
              <MenuItem value="1d">1 Day</MenuItem>
              <MenuItem value="2d">2 Days</MenuItem>
              <MenuItem value="3d">3 Days</MenuItem>
              <MenuItem value="4d">4 Days</MenuItem>
              <MenuItem value="5d">5 Days</MenuItem>
              <MenuItem value="6d">6 Days</MenuItem>
              <MenuItem value="7d">7 Days</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Workspace"
            name="workspace"
            value={formData.workspace}
            onChange={handleChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Subscription ID"
            name="az_sub_id"
            value={formData.az_sub_id}
            onChange={handleChange}
            required
            fullWidth
            error={formData.az_sub_id !== '' && !validateAzureSubscriptionId(formData.az_sub_id)}
            helperText={
              formData.az_sub_id !== '' && !validateAzureSubscriptionId(formData.az_sub_id)
                ? 'Invalid Azure subscription ID format.'
                : ''
            }
          />
          <Typography variant="body2" color="textSecondary">
            Example: e3aa3f60-88c4-4b4f-9e94-498aa4e1e152
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
    {loader}
    </>
  )
}

export default AzureForm