import React,{useState} from 'react'
import { Box, Paper,Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"
import { BaseUrl, SignInEndpoint, TenantDetails } from '../constants/constants';
import { useForm } from 'react-hook-form';
import axios, * as others from 'axios';
import swal from 'sweetalert';
import useFullPageLoader from './hooks/useFullPageLoader';
import Iconify from '../components/Iconify';
import {  Stack, IconButton, InputAdornment } from '@mui/material';
import Logo from './Logo';
import { motion } from "framer-motion";
import styled from "@emotion/styled";
let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};
const HeadingStyle = styled(Box)({
  textAlign: "center",
});
const Login = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const { register, formState: { errors }, handleSubmit } = useForm();
  const url = BaseUrl + SignInEndpoint;
  const [showPassword, setShowPassword] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  
  const onSubmit = data => {
    showLoader();
    if (agreeToTerms){
      axios.post(url,{
        email_address: data.email,
        password: data.password,
      })
      .then(res=>{
        hideLoader();
        if(res.status == '200'){
          window.localStorage.setItem("user_details", JSON.stringify(res));
            var config = {
              method: 'get',
              url: BaseUrl + TenantDetails,
              headers: { 
                'Authorization': `Bearer ${res.data.accessToken}`
              }
            };
          
            axios(config)
            .then(function (response) {
              window.localStorage.setItem("tenant_details", JSON.stringify(response.data));
              window.localStorage.setItem("tenant_name", JSON.stringify(response.data.tenant_name));
            })
            .catch(function (error) {
              
            });
          window.location.href = '/dashboard/home';
        }
      })
      .catch(function (error) {
        hideLoader();
        swal({
            title: error.response.data.non_field_errors,
            text: "Please provide correct email and password",
            icon: "error",
        })
      });
    }
    else {
      alert('Please agree to the Privacy Policy and License Terms & Conditions to log in.');
      hideLoader();
    }
  };

  return (
    <>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            padding:4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height:'100vh' 
          }}
        >
          <Paper
           sx={{
            padding:4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
           }}
          elevation={5}>
           <HeadingStyle component={motion.div} {...fadeInUp}>
            <Logo/>
            <Typography component="h1" variant="h5">
            Login to your account
            </Typography>
            </HeadingStyle>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                {...register("email", { required: true })}
              />
              {errors.email?.type === 'required' && "Email is required"}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password", { required: true })}
              />
              {errors.password && "Password is required"}
              <Grid container>
                <Grid item xs>
                <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="right"
          >
                  <Link to="/resetpassword" underline="hover" style={{  color: 'black'}}>
                    Forgot password?
                  </Link>
                  </Typography>
                </Grid>
              </Grid>
              <br/>
              <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    By logging in, you agree to the <a href="/Khika-Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/Khika-License-Terms-Conditions 1.pdf" target="_blank" rel="noopener noreferrer">License Terms & Conditions</a>
                  </span>
                </div>
              </div>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="right"
           
          >  
                  Don’t have an account?{" "}
            <Link variant="subtitle2" component={RouterLink} to="/signup" style={{  color: 'black'}}>
              Sign up
            </Link>
            </Typography>
                </Grid>
              </Grid>
            </Box>
            </Paper>
          </Box>
      </Container>
      {loader}
    </>
  )
}

export default Login