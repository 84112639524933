import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import axios from 'axios';
import { BaseUrl, CloudDevices } from '../../constants/constants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import swal from 'sweetalert';

const AwsForm = () => {

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [formData, setFormData] = useState({
    aws_acc_id: '',
    cron: 'None',
    service: 'cloudtrail',
    access_key: '',
    secret_key: '',
    bucket: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    showLoader();
    var data = JSON.stringify({
      "aws_acc_id": formData.aws_acc_id,
      "cron": formData.cron,
      "service": "cloudtrail",
      "access_key": formData.access_key,
      "secret_key": formData.secret_key,
      "bucket": formData.bucket
    });

    var config = {
      method: 'post',
      url: BaseUrl + CloudDevices + '/aws/cloudtrail',
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      hideLoader();
      swal({
        title: "Account Created Successfully",
        text: "",
        icon: "success",
      }).then(function() {
        window.location.href = '/dashboard/cloud'
      });
    })
    .catch(function (error) {
      console.log(error);
    });

  };

  const awsAccountIdRegex = /^[0-9]{12}$/;

  function isAwsAccountIdValid(awsAccountId) {
    return !awsAccountId || awsAccountIdRegex.test(awsAccountId);
  }

  const accessKeyIdRegex = /^[A-Z0-9]{20}$/;

  function isAccessKeyIdValid(accessKeyId) {
    return !accessKeyId || accessKeyIdRegex.test(accessKeyId);
  }

  function isBucketNameValid(bucketName) {
    const regex = /^[a-z0-9][a-z0-9\-]{1,61}[a-z0-9]$/;
    return regex.test(bucketName);
  }

  const [showSecretKey, setShowSecretKey] = useState(false);

  return (
    <>
    <br />
    <br />
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="AWS Account ID"
            name="aws_acc_id"
            value={formData.aws_acc_id}
            onChange={handleChange}
            required
            fullWidth
            error={!isAwsAccountIdValid(formData.aws_acc_id)}
            helperText={isAwsAccountIdValid(formData.aws_acc_id) ? '' : 'Invalid AWS account ID format'}
          />
          <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
            Example: 123456789012
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <FormControl fullWidth>
            <InputLabel>Cron</InputLabel>
            <Select
              name="cron"
              value={formData.cron}
              onChange={handleChange}
              required
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="@once">@once</MenuItem>
              <MenuItem value="@hourly">@hourly</MenuItem>
              <MenuItem value="@daily">@daily</MenuItem>
              <MenuItem value="@weekly">@weekly</MenuItem>
              <MenuItem value="@monthly">@monthly</MenuItem>
              <MenuItem value="@yearly">@yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Service"
            name="service"
            value="cloudtrail"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Access Key"
            name="access_key"
            value={formData.access_key}
            onChange={handleChange}
            required
            fullWidth
            error={!isAccessKeyIdValid(formData.access_key)}
            helperText={isAccessKeyIdValid(formData.access_key) ? '' : 'Invalid AWS access key ID format'}
          />
          <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
            Example: AKIAIOSFODNN7EXAMPLE
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Secret Key"
            name="secret_key"
            value={formData.secret_key}
            onChange={handleChange}
            required
            fullWidth
            type={showSecretKey ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowSecretKey(!showSecretKey)} edge="end" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Bucket"
            name="bucket"
            value={formData.bucket}
            onChange={handleChange}
            required
            fullWidth
            error={formData.bucket !== '' && !isBucketNameValid(formData.bucket)}
            helperText={formData.bucket !== '' && !isBucketNameValid(formData.bucket) ? 'Invalid bucket name format' : ''}
          />
          <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
            Example: my-s3-bucket
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
    {loader}
    </>
  )
}

export default AwsForm