import React from 'react';
import axios from 'axios';
import { BaseUrl } from '../../constants/constants';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const loadChatHistory = () => {
    const savedMessages = JSON.parse(localStorage.getItem('chatHistory')) || [];
    // console.log("Loading messages:", savedMessages);

    const reconstructedMessages = savedMessages.map(msg => {
      if (msg.type === 'bot') {
        return {
          ...msg,
          message: createChatBotMessage(
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.content}</ReactMarkdown>,
            { withAvatar: true }
          ).message,
        };
      }
      return msg;
    });

    setState((prev) => ({
      ...prev,
      messages: reconstructedMessages,
    }));
  };

  const saveChatHistory = (messages) => {
    const serializedMessages = messages.map(msg => {
      if (msg.type === 'bot') {
        return {
          ...msg,
          content: msg.message.props.children, // Save the plain text for bot messages
        };
      }
      return msg;
    });
    // console.log("Saving messages:", serializedMessages);

    localStorage.setItem('chatHistory', JSON.stringify(serializedMessages));
  };

  const handleAPIQuery = (query) => {
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken;
    const data = JSON.stringify({ "chat_query": query });

    const typingMessage = createChatBotMessage("Typing...", { typing: true });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, typingMessage],
    }));

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/user/chatquery',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        const apiMessageContent = response.data; // Assuming this is plain text
        const apiMessage = createChatBotMessage(
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{apiMessageContent}</ReactMarkdown>,
          { withAvatar: true }
        );
        setState((prev) => {
          const newMessages = prev.messages.slice(0, -1); // Remove typing indicator
          newMessages.push(apiMessage);
          saveChatHistory(newMessages); // Save updated history
          return {
            ...prev,
            messages: newMessages,
          };
        });
      })
      .catch((error) => {
        const errorMessage = createChatBotMessage(`API Error: ${error.message}`);
        setState((prev) => {
          const newMessages = prev.messages.slice(0, -1); // Remove typing indicator
          newMessages.push(errorMessage);
          saveChatHistory(newMessages); // Save updated history
          return {
            ...prev,
            messages: newMessages,
          };
        });
      });
  };

  React.useEffect(() => {
    loadChatHistory();
  }, []);

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleAPIQuery,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;