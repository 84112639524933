import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Backdrop, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import { BaseUrl } from '../constants/constants';

const ManageUsers = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '' });

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;

  const user_role = window.localStorage.getItem("user_role");

  const fetchData = async () => {
    setLoading(true);
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseUrl + '/user/list_rousers',
      headers: { 
        'Authorization': `Bearer ${token}`,
      }
    };

    try {
      const response = await axios.request(config);
      const data = response.data.map((user, index) => ({
        id: index + 1,
        fullName: `${user.first_name} ${user.last_name}`,
        email: user.email_address,
        company_name: user.company_name
      }));
      setRows(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpen = () => {
    if (user_role === 'user_ro') {
      swal({
        icon: 'error',
        title: 'Permission Denied',
        text: 'You do not have permission to invite users.'
      });
    } else if (rows.length >= 5) {
      swal({
        icon: 'error',
        title: 'User Limit Exceeded',
        text: 'You can add up to 5 additional users.'
      });
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const validateName = (name) => {
    const re = /^[A-Za-z]{4,}$/;
    return re.test(String(name));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateName(newUser.firstName)) {
      swal({
        icon: 'error',
        title: 'Invalid First Name',
        text: 'First name should contain only alphabets and be at least 4 characters long.'
      });
      return;
    }
  
    if (!validateName(newUser.lastName)) {
      swal({
        icon: 'error',
        title: 'Invalid Last Name',
        text: 'Last name should contain only alphabets and be at least 4 characters long.'
      });
      return;
    }
  
    if (!validateEmail(newUser.email)) {
      swal({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address.'
      });
      return;
    }
  
    setLoading(true);
    const data = JSON.stringify({
      first_name: newUser.firstName,
      last_name: newUser.lastName,
      email_address: newUser.email
    });
  
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/auth/register_ro',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
  
    try {
      const response = await axios.request(config);
      handleClose();
      swal({
        icon: 'success',
        title: 'User Invited!',
        text: 'The user has been successfully invited.'
      }).then(() => {
        fetchData();
      });
    } catch (error) {
      console.error('Error adding user:', error);
      swal({
        icon: 'error',
        title: 'Error',
        text: 'Failed to invite the user. Please try again later.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (email) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteUserData(email);
      }
    });
  };

  const deleteUserData = async (email) => {
    setLoading(true);

    const data = JSON.stringify({
      email_address: email
    });

    const config = {
      method: 'delete',
      url: `${BaseUrl}/auth/delete_ro_user`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      swal({
        icon: 'success',
        title: 'User Deleted!',
        text: 'The user has been successfully deleted.'
      }).then(() => {
        fetchData();
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      swal({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete the user. Please try again later.'
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'fullName', headerName: 'Full Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            // color="error"
            sx={{ backgroundColor: 'red', color: 'white' }} 
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => handleDelete(params.row.email)}
            disabled={user_role === 'user_ro'}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Box sx={{ p: 2 }}>
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            Invite User
          </Button>
        </Box>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <div style={{ height: 440, width: '100%' }}>
            <style>
              {`
                .MuiDataGrid-columnHeader {
                  background-color: green;
                  color: white;
                }
                .MuiDataGrid-cell {
                  color: black;
                }
                .MuiDataGrid-footerContainer {
                  background-color: lightgray;
                }
                .MuiDataGrid-row:nth-of-type(odd) {
                  background-color: lightyellow;
                }
                .MuiDataGrid-row:nth-of-type(even) {
                  background-color: white;
                }
              `}
            </style>
              <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
            </div>
          </Box>
        </PerfectScrollbar>
      </Card>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            value={newUser.firstName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            value={newUser.lastName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={newUser.email}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={loading} sx={{ color: '#fff', zIndex: 1500  }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ManageUsers;