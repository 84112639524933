import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Tooltip, IconButton, Button, Stack, Modal, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import TimezoneSelect from 'react-timezone-select';
import cronstrue from 'cronstrue';
import { BaseUrl } from '../constants/constants';
import { Close } from '@mui/icons-material';
import swal from 'sweetalert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

const Custom_Reports = () => {
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [openTimeRangeModal, setOpenTimeRangeModal] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [cronValue, setCronValue] = useState('');
  const [error, setError] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState({ value: 'UTC', label: 'UTC' });
  const [mailList, setMailList] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  
  const fetchData = async () => {
    try {
      const response = await axios.get(BaseUrl + '/reports/', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setData(response.data.list_report);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const updateReportStatus = async (id, status) => {
    try {
      const response = await axios.patch(BaseUrl + '/reports/status', 
        {
          khika_report_id: id,
          status: status,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setData((prevData) =>
        prevData.map((row) =>
          row.khika_report_id === id ? { ...row, status } : row
        )
      );
      window.location.reload();
    } catch (error) {
      console.error('Error updating report status:', error);
    }
  };

  const handleActivate = (id) => {
    updateReportStatus(id, true);
  };

  const handleDeactivate = (id) => {
    updateReportStatus(id, false);
  };

  const handleConfig = (id) => {
    const selectedReport = data.find((report) => report.khika_report_id === id);
    if (selectedReport) {
      setSelectedReportId(id);
      setCronValue(selectedReport.cron_val || '');
      setSelectedTimezone({
        value: selectedReport.time_zone || 'UTC',
        label: selectedReport.time_zone || 'UTC'
      });
      setOpenModal(true);
    }
  };
  
  const handleUpdate = (id) => {
    const selectedReport = data.find((report) => report.khika_report_id === id);
    if (selectedReport) {
      setSelectedReportId(id);
      setCronValue(selectedReport.cron_val || '');
      setMailList(selectedReport.mail_list || '');
      setSelectedTimezone({
        value: selectedReport.time_zone || 'UTC',
        label: selectedReport.time_zone || 'UTC'
      });
      setOpenSettingModal(true);
    }
  };  

  const [startdate, setStartDate] = React.useState(dayjs().subtract(24, 'hour'));
  const [enddate, setEndDate] = React.useState(dayjs());
  const [offset, setOffSet] = React.useState('');

  dayjs.extend(duration);

  React.useEffect(() => {
    if (offset) {
      const parsedDuration = dayjs.duration(offset);
      const endDate = dayjs();
      const startDate = endDate.subtract(parsedDuration);
      setEndDate(endDate);
      setStartDate(startDate);
    } else {
      const endDate = dayjs();
      const startDate = endDate.subtract(24, 'hour');
      setEndDate(endDate);
      setStartDate(startDate);
    }
  }, [offset]);

  const handleTimeRange = (data) => {
    setOffSet(data.report_timeoffset);
    setSelectedReportId(data.khika_report_id);
    setOpenTimeRangeModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedReportId(null);
    setCronValue('');
    setSelectedTimezone('');
  };

  const handleCloseSettingModal = () => {
    setOpenSettingModal(false);
    setSelectedReportId(null);
    setCronValue('');
    setSelectedTimezone('');
  };

  const handleSave = async () => {
    if (!cronValue || !selectedTimezone) {
      alert('Please fill in all required fields.');
      return;
    }
    
    const value = cronValue;

    try {
      cronstrue.toString(value);
      setError('');
    } catch (err) {
      setError('Invalid cron expression');
      return;
    }

    try {
      await axios.post(BaseUrl + '/reports/', 
        {
          khika_report_id: selectedReportId,
          cron_val: cronValue,
          time_zone: selectedTimezone.value || 'UTC',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      handleCloseModal();
      swal('Success', 'Report added successfully', 'success').then(() => {
        window.location.reload();
      });
      fetchData();
    } catch (error) {
      console.error('Error saving report config:', error);
    }
  };

  const validateEmails = (emails) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emails.split(',').every(email => {
        const trimmedEmail = email.trim();
        return trimmedEmail === '' || emailPattern.test(trimmedEmail);
    });
  };

  const handleSaveSetting = async () => {
    if (!cronValue || !selectedTimezone) {
      alert('Please fill in all required fields.');
      return;
    }

    if (!validateEmails(mailList)) {
      alert('Please enter valid email addresses, separated by commas.');
      return;
    }

    const value = cronValue;

    try {
      cronstrue.toString(value);
      setError('');
    } catch (err) {
      setError('Invalid cron expression');
      return;
    }

    let data = JSON.stringify({
    "khika_report_id": selectedReportId,
    "mail_list": mailList,
    "cron_val": cronValue,
    "time_zone": selectedTimezone.value || 'UTC',
    });
    
    let config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: BaseUrl + '/reports/report_config',
    headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
    },
    data : data
    };

    axios.request(config)
    .then((response) => {
      swal('Success', 'Report updated successfully', 'success').then(() => {
        window.location.reload();
      });
    })
    .catch((error) => {
    
    });
  }

  const columns = [
    { field: 'title', headerName: 'Title', flex: 2, cellClassName: 'word-wrap' },
    {
      field: 'cron_val',
      headerName: 'Cron',
      flex: 1,
      valueFormatter: (params) => cronstrue.toString(params.value),
    },
    {
      field: 'next_run',
      headerName: 'Next Run',
      flex: 1.5,
      cellClassName: 'word-wrap',
      renderCell: (params) => {
        const { status, next_run, time_zone } = params.row;
        let formattedTimestamp = next_run;
    
        if (status === false && next_run === null) {
          formattedTimestamp = "Report Disabled";
        } else if (status === true && next_run === '-') {
          formattedTimestamp = "Scheduling";
        } else if (next_run !== "Report Disabled" && next_run !== '-' && next_run !== null) {
          const utcTimestamp = new Date(next_run);
    
          if (!isNaN(utcTimestamp.getTime())) {
            const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
              timeZone: time_zone,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            });
            formattedTimestamp = dateTimeFormatter.format(utcTimestamp) + " " + time_zone;
          } else {
            formattedTimestamp = "Report Disabled";
          }
        }
    
        return <>{formattedTimestamp === '-' ? "Report Disabled" : formattedTimestamp}</>;
      },
    },     
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueFormatter: (params) => {
        if (params.value === true) return 'Enabled';
        if (params.value === false) return 'Disabled';
        return 'Report Disabled';
      },
    },
    {
      field: 'info',
      headerName: 'Description',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.desc} placement="top">
          <IconButton>
            <InfoIcon 
            sx={{ 
                color: 'info.main',
                fontSize: 30
            }}
            />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.5,
      renderCell: (params) => {
        const { status, khika_report_id } = params.row;
        return (
          <Stack direction="row" spacing={1}>
            {status === true ? (
              <>
                <Button variant="outlined" color='error' sx={{ width: '120px' }} onClick={() => handleDeactivate(khika_report_id)}>
                  Deactivate
                </Button>
                <IconButton onClick={() => handleUpdate(khika_report_id)}>
                  <SettingsIcon />
                </IconButton>
                <IconButton onClick={() => handleTimeRange(params.row)}>
                  <MoreTimeIcon />
                </IconButton>
              </>
            ) : status === false ? (
              <>
                <Button variant="outlined" sx={{ width: '120px' }} onClick={() => handleActivate(khika_report_id)}>
                  Activate
                </Button>
                <IconButton onClick={() => handleUpdate(khika_report_id)}>
                  <SettingsIcon />
                </IconButton>
                <IconButton onClick={() => handleTimeRange(params.row)}>
                  <MoreTimeIcon />
                </IconButton>
              </>
            ) : (
              <Button variant="contained" sx={{ width: '120px' }} color='info' onClick={() => handleConfig(khika_report_id)}>
                Report Config
              </Button>
            )}
          </Stack>
        );
      },
    }
  ];  

  const filteredData = data.filter((row) =>
    row.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const [openCronModal, setOpenCronModal] = React.useState(false);
  const [cronContent, setCronContent] = React.useState('');

  const handleOpenCronModal = async () => {
    try {
      const response = await fetch('/crontab_setup.txt');
      const text = await response.text();
      setCronContent(text);
      setOpenCronModal(true);
    } catch (error) {
      console.error('Error fetching cron setup file:', error);
    }
  };

  const handleCloseCronModal = () => {
    setOpenCronModal(false);
  };

  const handleCloseTimeRangeModal = () => {
    setOpenTimeRangeModal(false);
  };

  const addreportstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginLeft: '0%',
    height: 375,
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow:"auto",
    p: 4,
  };

  const handleTimeRangeSave = async () => {
    if (startdate && enddate) {
      const diffInMilliseconds = dayjs(enddate).diff(startdate);
      const dur = dayjs.duration(diffInMilliseconds);
      const totalHours = dur.days() * 24 + dur.hours();
      const minutes = dur.minutes();
      const seconds = dur.seconds();
      const milliseconds = dur.milliseconds();
      const formattedDuration = `PT${totalHours}H${minutes}M${seconds}.${milliseconds}S`;

      let data = JSON.stringify({
        "khika_report_id": selectedReportId,
        "time_offset": formattedDuration
      });

      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: BaseUrl + '/reports/time_offset',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`
        },
        data: data
      };
      try {
        const response = await axios.request(config);
        handleCloseTimeRangeModal();
      } catch (error) {
        
      }
    } else {
      
    }
  }
  return (
    <Box sx={{ height: '85vh', width: '100%', marginTop: '1%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5" component="h1" style={{ marginLeft: '1%'}}>
            Reports Configuration
          </Typography>
          <TextField 
            label="Search by Title Name" 
            variant="outlined" 
            size="small" 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: '500px', marginRight: '1%' }}
          />
        </Box>
        <br />
      {loading ? (
        <>
        <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%', 
          width: '100%' 
        }}
        >
        <CircularProgress />
        </Box>
        </>
      ) : (
        <>
        <div style={{ height: '75vh', width: '100%' }}>
        <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
        </style>
        <DataGrid
          rows={filteredData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.khika_report_id}
        />
        </div>
        </>
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="report-config-modal"
        aria-describedby="report-config-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}>
          <Typography id="report-config-modal" variant="h6" component="h2">
            <center>Configure Report</center>
          </Typography>
          <br />
          <Typography>
          </Typography>
          <TextField
            label="Cron Expression"
            value={cronValue}
            onChange={(e) => setCronValue(e.target.value)}
            fullWidth
            margin="normal"
            required
            error={!!error}
            helperText={error}
          />
          <TimezoneSelect
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            margin="normal"
          />
          <br />
          <b>Note: This report will include data from the last 24 hours only.</b>
          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
            <Button onClick={handleOpenCronModal} variant='outlined' color='info' style={{ marginRight: '35%' }}>How to set Cron?</Button>
            <Button onClick={handleCloseModal} variant="outlined">Cancel</Button>
            <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={openSettingModal}
        onClose={handleCloseSettingModal}
        aria-labelledby="report-update-modal"
        aria-describedby="report-update-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}>
          <Typography id="report-update-modal" variant="h6" component="h2">
            <center>Update Config</center>
          </Typography>
          <br />
          <TextField
            label="Cron Expression"
            value={cronValue}
            onChange={(e) => setCronValue(e.target.value)}
            fullWidth
            margin="normal"
            error={!!error}
            helperText={error}
          />
          <TextField
            label="Email List"
            value={mailList}
            onChange={(e) => setMailList(e.target.value)}
            fullWidth
            margin="normal"
            helperText="Please keep emails comma-separated"
           />
          <TimezoneSelect
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            margin="normal"
          />
          <br />
          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
            <Button onClick={handleOpenCronModal} variant='outlined' color='info' style={{ marginRight: '35%' }}>How to set Cron?</Button>
            <Button onClick={handleCloseSettingModal} variant="outlined">Cancel</Button>
            <Button onClick={handleSaveSetting} variant="contained" color="primary">Save</Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
          open={openCronModal}
          onClose={handleCloseCronModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={addreportstyle}>
            <Close onClick={handleCloseCronModal} style={{marginLeft: '90%', cursor:'pointer'}}/>
            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{cronContent}</pre>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={openTimeRangeModal}
          onClose={handleCloseTimeRangeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box 
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 1,
            }}
          >
            <Close onClick={handleCloseTimeRangeModal} style={{ marginLeft: '90%', cursor: 'pointer' }} />
            <Typography id="report-update-modal" variant="h6" component="h2">
              <center>Update Time Range</center>
            </Typography>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField 
                      {...props} 
                      fullWidth
                      size="small"
                    />
                  )}
                  label="Start Date"
                  value={startdate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                />
                
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField 
                      {...props} 
                      fullWidth
                      size="small"
                    />
                  )}
                  label="End Date"
                  value={enddate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                />
              </Box>
            </LocalizationProvider>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button variant="contained" color="primary" onClick={handleTimeRangeSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Modal>
    </Box>
  );
};

export default Custom_Reports;