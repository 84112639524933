import { BaseUrl } from '../constants/constants';
import axios  from 'axios';

export async function getTenantDetails() {
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken
  var config = {
    method: 'get',
    url: BaseUrl+'/tenant',
    headers: { 
      'Authorization': 'Bearer '+token
    }
  };

  let res=await axios(config)
  return res.data

  }