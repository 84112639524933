import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import logo from '../images/khikalogo.png'

const Logo = () => {
  return (
    
    <Box sx={{width: 300}}>
      <Link to="/">
        <Box component="img" src={logo} alt="logo" />
      </Link>
    </Box>
  );
};

export default Logo;