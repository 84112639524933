import * as React from 'react';
import Navbar from '../components/Navbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import {useEffect,useState} from 'react';
import { Outlet } from 'react-router-dom';
import { getUserDetails } from '../service/UserDetails';
import { Box, CircularProgress, Skeleton } from '@mui/material';


export default function DashboardLayout() {

  const user_details = JSON.parse(window.localStorage.getItem("user_details"))['data'];
  const [userDetails, setUserDetails] = useState();
  const [apiCall, setApiCall] = useState(false);
  useEffect(() => {
    setApiCall(true)
    
    getUserDetails()
      .then(resp => {
        setUserDetails(resp);
        setApiCall(false);
      })
   
  }, [])

  return (
    <>
   
    {apiCall?   <Box>
      
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>:
    <>
    <Navbar UserData={userDetails} />
      <Box>
        <Outlet />
      </Box> </>
  
    }
      </>
  );
}
