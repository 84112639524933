import Switch from "react-switch";
import React, { useState } from 'react'
import { ActiveResponse, BaseUrl, TenantDetails } from '../constants/constants';
import axios from 'axios';
import { Typography } from "@mui/material";

const Toggle = () => {
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken
    const user_role = window.localStorage.getItem("user_role");
    const [checked, setChecked] = useState();

    var config = {
    method: 'get',
    url: BaseUrl + TenantDetails,
    headers: { 
        'Authorization': `Bearer ${token}`
    }
    };

    axios(config)
    .then(function (response) {
        setChecked(response.data.active_response);
    })
    .catch(function (error) {
        console.log(error);
    });

    const handleChange = nextChecked => {
        setChecked(nextChecked);
        try
        {
            var config = {
                method: 'post',
                url: BaseUrl + ActiveResponse + !checked,
                headers: { 
                    'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
            .then(function (response) {
                setChecked(!checked);
            })
            .catch(function (error) {
                console.log(error);
            });
        } 
        catch (error) {
            console.error(error);
        }
    };    

    return (
    <>
    <div className="toggle">
        <label>
            <Switch
            onChange={handleChange}
            checked={checked}
            className="react-switch"
            disabled={user_role === 'user_ro'}
            />
        </label>
        <Typography>
            <div className="ar-status">
                Active Response is <span>{checked ? "active" : "inactive"}</span>
            </div>
        </Typography>
    </div>
    </>
    )
}

export default Toggle