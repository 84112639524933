import React, { useState, useEffect } from 'react';

const Discover = () => {
    const [tenantname, setTenantname] = useState(null);
    const [kibanaToken, setKibanaToken] = useState(null);

    useEffect(() => {
        const user_details = JSON.parse(window.localStorage.getItem("user_details"));
        const tenantname = JSON.parse(window.localStorage.getItem("tenant_name"));
        const kibanaToken = user_details?.data?.kibana_token;

        if (tenantname && kibanaToken) {
            setTenantname(tenantname);
            setKibanaToken(kibanaToken);
        }
    }, []);

    return (
        <>
            {tenantname && kibanaToken && (
                <div style={{ width: '100%', height: 'calc(100vh - 20px)', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <iframe
                        src={`https://saas.khika.in/kibana/app/discover?security_tenant=${tenantname}&auth_token=${kibanaToken}#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(columns:!(_source),filters:!(),index:'553b6600-5095-11ef-94a5-9d045b0c62c4',interval:auto,query:(language:kuery,query:''),sort:!())&embed=true&show-time-filter=true`}
                        style={{ width: '100%', height: '100%', flexGrow: 1 }}
                        title="Discover"
                        frameBorder="0"
                    ></iframe>
                </div>
            )}
        </>
    );
};

export default Discover;