import React, { useState } from 'react'
import { Box, Button, ButtonGroup, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { BaseUrl, PasswordReset } from '../constants/constants';
import axios, * as others from 'axios';
import useFullPageLoader from './hooks/useFullPageLoader';
import swal from 'sweetalert';

const theme = createTheme();

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleSubmit = async (event) => {
    showLoader();
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(email)) {
      hideLoader();
      return swal({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address.',
      });
    }

    try {
      const response = await axios.post(BaseUrl + PasswordReset, {
        email_address: email
      });
      hideLoader();
      swal({
        icon: 'success',
        title: 'Reset Password Link Sent!',
        text: 'Please check your email.',
      }).then(function() {
        window.location.href = '/'
      });
    } catch (error) {
      hideLoader();
      swal({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box sx={{ mt: 1, width: 400 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <ButtonGroup sx={{ mt: 3, mb: 2, width: 250 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Submit
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ left: 150 }}
                >
                  <Link to="/" style={{ textDecoration: 'none', color: 'white'}}>
                    Cancel
                  </Link>
                </Button>
              </ButtonGroup>
            </Box>
            </form>
          </Box>
      </Container>
      {loader}
    </>
  )
}

export default ResetPassword