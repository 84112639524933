import React from 'react'
import axios from 'axios';
import { BaseUrl, CloudDevices } from '../../constants/constants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { Container } from '@mui/system';
import swal from 'sweetalert';

const UpdateCloud = () => {
    
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken

    const [type, setType] = React.useState('');
    const [data, setData] = React.useState({});
    const [cron, setCron] = React.useState('');
    const [offset, setOffset] = React.useState('');

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    React.useEffect(() => {
        fetchData('');
        
      }, []);
  
      const fetchData = async => {
        showLoader();
        
        var config = {
        method: 'get',
        url: BaseUrl + CloudDevices + '/' + id,
        headers: { 
            'Authorization': `Bearer ${token}`,
        }
        };

        axios(config)
        .then(function (response) {
            hideLoader();
            setType(response.data.type)
            setData(response.data)
            setCron(response.data.config_json.cron)
            setOffset(response.data.config_json.time_offset)
        })
        .catch(function (error) {
            console.log(error);
        });
      }

      const handleChange = (event) => {
        setCron(event.target.value);
      };

      const handleOffsetChange = (event) => {
        setOffset(event.target.value);
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        showLoader();
    
        const dataJson = offset 
          ? JSON.stringify({
              "cron": cron,
              "time_offset": offset
            })
          : JSON.stringify({
              "cron": cron,
            });
        console.log(dataJson);
        const data = JSON.parse(dataJson);

        var config = {
          method: 'patch',
          url: BaseUrl + CloudDevices + '/' + id,
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data : data
        };
      
        axios(config)
        .then(function (response) {
          hideLoader();
          swal({
            title: "Changes Updated Successfully",
            text: "",
            icon: "success",
          }).then(function() {
            window.location.href = '/dashboard/cloud'
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      };

  return (
    <>
    <Container>
        <Button variant='outlined' className='syslog-back-btn'><Link to="/dashboard/cloud" style={{ textDecoration: 'none', color: '#00AB55', textTransform: 'capitalize'}}>Back</Link></Button>
        {type === 'aws' ?
        <form onSubmit={handleSubmit}>
          <br />
          <br />
          <br />
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="AWS Account ID"
            name="aws_acc_id"
            value={data.acc_id}
            fullWidth
            disabled
          />
          </Grid>
          <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <FormControl fullWidth>
            <InputLabel>Cron</InputLabel>
            <Select
              name="cron"
              value={cron}
              required
              onChange={handleChange}
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="@once">@once</MenuItem>
              <MenuItem value="@hourly">@hourly</MenuItem>
              <MenuItem value="@daily">@daily</MenuItem>
              <MenuItem value="@weekly">@weekly</MenuItem>
              <MenuItem value="@monthly">@monthly</MenuItem>
              <MenuItem value="@yearly">@yearly</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Service"
            name="service"
            value={data.service}
            disabled
            fullWidth
          />
          </Grid>
          <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <TextField
            label="Access Key"
            name="access_key"
            value={data.config_json.access_key}
            disabled
            fullWidth
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
            label="Secret Key"
            name="secret_key"
            value={data.config_json.secret_key}
            disabled
            fullWidth
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
            label="Bucket"
            name="bucket"
            value={data.config_json.bucket}
            disabled
            fullWidth
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
        </Grid>        
        </form>
        : 
        <form onSubmit={handleSubmit}>
          <br />
          <br />
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <FormControl fullWidth>
            <InputLabel>Cron</InputLabel>
            <Select
              name="cron"
              value={cron}
              onChange={handleChange}
              required
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="@once">@once</MenuItem>
              <MenuItem value="@hourly">@hourly</MenuItem>
              <MenuItem value="@daily">@daily</MenuItem>
              <MenuItem value="@weekly">@weekly</MenuItem>
              <MenuItem value="@monthly">@monthly</MenuItem>
              <MenuItem value="@yearly">@yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} sx={{marginBottom : 2}}>
          <FormControl fullWidth>
            <InputLabel>Time Offset</InputLabel>
            <Select
              name="Time Offset"
              value={offset}
              onChange={handleOffsetChange}
              required
            >
              <MenuItem value="1d">1 Day</MenuItem>
              <MenuItem value="2d">2 Days</MenuItem>
              <MenuItem value="3d">3 Days</MenuItem>
              <MenuItem value="4d">4 Days</MenuItem>
              <MenuItem value="5d">5 Days</MenuItem>
              <MenuItem value="6d">6 Days</MenuItem>
              <MenuItem value="7d">7 Days</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
        </form>
        }
    </Container>
    {loader}
    </>
  )
}

export default UpdateCloud