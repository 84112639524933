import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { BaseUrl, DeleteAgent, DevicesList, SupportedPlatform,InstallCmd } from './constants/constants';
import Navbar from './components/Navbar'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import axios from 'axios';
import { Container, Stack } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import { Card, CardHeader } from '@mui/material';
import { CopyBlock, dracula } from "react-code-blocks";
import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const steps = [
  1, 2, 3
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function ConnectDevices() {
  const [SPlatforms, setSPlatforms] = React.useState();
  const [instLoading, setInstLoading] = React.useState(true);
  const [instructions, setInstructions] = React.useState({});
  const [loading, setLoading] = React.useState(true)
  const [apiLoading, setApiLoading] = React.useState(false)
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken
  React.useEffect(() => {
    var config = {
      method: 'get',
      url: BaseUrl + SupportedPlatform,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(function (response) {
        window.localStorage.setItem("supported_platforms", JSON.stringify(response.data));
        setSPlatforms(response.data)
        setLoading(false)

      })
      .catch(function (error) {

      });
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedData, setSelectedData] = React.useState({});
  const [selectedOSData, setSelectedOSData] = React.useState({});
  const [selectedVerData, setSelectedVerData] = React.useState({});
  const [selectedArcData, setSelectedArcData] = React.useState({});
  const [is_end, setEnd] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   const os_labels = supported_platforms['agent_command']['os_platforms'];
  const handleNext = (type, id, data) => {
    let updateJson = {};
    updateJson[type] = id
    setSelectedData(selectedData => ({ ...selectedData, ...updateJson, }))
    if (type == 'os') {
      if (!data.hasOwnProperty('versions') && !data.hasOwnProperty('architecture')) {
        setEnd(true)
      }
      setSelectedOSData(data)
    }
    if (type == 'versions') {
      setSelectedVerData(data)
    }
    if (type == 'architecture') {
      setSelectedArcData(data);
      setEnd(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setSelectedData({})
    setEnd(false);
    setInstLoading(true);
    setSelectedOSData({})
    setSelectedArcData({})
    setSelectedVerData({})
    setActiveStep(0);
    setInstructions({});
  };

  const handleGenerate = () => {
    setApiLoading(true)
    var config = {
      method: 'post',
      url: BaseUrl + InstallCmd,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data:{
        "selectedOS":selectedOSData['id']?selectedOSData['id']:'',
        "selectedVersion":selectedVerData['id']?selectedVerData['id']:'',
        "selectedArchitecture":selectedArcData['id']?selectedArcData['id']:''
      }
    };

    axios(config)
      .then(function (response) {
        setInstructions(response.data)
        setInstLoading(false)
        setApiLoading(false)

      })
      .catch(function (error) {

      });
  };
  return (
    <>
      <Container>


        {loading ? <><Box >
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box></> :
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ maxWidth: 400, p: 4 }} m="auto" >
                <Paper elevation={3} sx={{ p: 2 }} m="auto">
                  <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key="os">
                      <StepLabel>
                        {selectedData.hasOwnProperty('os') ? <> <span>Selected Operating System </span> <Chip label={selectedOSData['label']} color="success" /></> : <span>Select the Operating System </span>}
                      </StepLabel>
                      <StepContent>
                        <Box sx={{ mb: 2 }}>
                          <div>
                            {
                              SPlatforms?.agent_command.os_platforms.map(element => {
                                return <Button key={element.id}
                                  variant="contained"
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() => handleNext('os', element.id, element)}
                                >{element.label}</Button>
                              })
                            }
                            <Button
                              disabled
                              onClick={handleReset}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Reset
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                    {
                      selectedData.hasOwnProperty('os') && selectedOSData.hasOwnProperty('versions') ?
                        <Step key="versions">
                          <StepLabel>
                            {selectedData.hasOwnProperty('versions') ? <> <span>Selected Version </span> <Chip label={selectedVerData['label']} color="success" /></> : <span>Select the Version </span>}
                          </StepLabel>
                          <StepContent>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                {
                                  selectedOSData['versions'].map(elev => {
                                    return <Button key={elev.id}
                                      variant="contained"
                                      sx={{ mt: 1, mr: 1 }}
                                      onClick={() => handleNext('versions', elev.id, elev)}
                                    >{elev.label}</Button>
                                  })
                                }
                                <Button

                                  onClick={handleReset}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Reset
                                </Button>
                              </div>
                            </Box>
                          </StepContent>
                        </Step> : ''
                    }
                    {
                      selectedData.hasOwnProperty('os') && selectedOSData.hasOwnProperty('architecture') ?
                        <Step key="architecture">
                          <StepLabel>
                            {selectedData.hasOwnProperty('architecture') ? <> <span>Selected Architecture</span> <Chip label={selectedArcData['label']} color="success" /></> : <span>Select the Architecture </span>}
                          </StepLabel>
                          <StepContent>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                {
                                  selectedOSData['architecture'].map(elev => {
                                    return <Button key={elev.id}
                                      variant="contained"
                                      sx={{ mt: 1, mr: 1 }}
                                      onClick={() => handleNext('architecture', elev.id, elev)}
                                    >{elev.label}</Button>
                                  })
                                }
                                <Button

                                  onClick={handleReset}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Reset
                                </Button>
                              </div>
                            </Box>
                          </StepContent>
                        </Step> : ''
                    }
                    {
                      selectedData.hasOwnProperty('versions') && selectedVerData.hasOwnProperty('architecture') ?
                        <Step key="architecture">
                          <StepLabel>
                            {selectedData.hasOwnProperty('architecture') ? <> <span>Selected Architecture</span> <Chip label={selectedArcData['label']} color="success" /></> : <span>Select the Architecture </span>}
                          </StepLabel>
                          <StepContent>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                {
                                  selectedVerData['architecture'].map(element => {
                                    return <Button key={element.id}
                                      variant="contained"
                                      sx={{ mt: 1, mr: 1 }}
                                      onClick={() => handleNext('architecture', element.id, element)}
                                    >{element.label}</Button>
                                  })
                                }
                                <Button
                                  onClick={handleReset}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Reset
                                </Button>
                              </div>
                            </Box>
                          </StepContent>
                        </Step> : ''
                    }
                  </Stepper>
                  {is_end && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                      <Typography>Get Instructions</Typography>
                      <Button onClick={handleGenerate} sx={{ mt: 1, mr: 1 }} variant="outlined">
                        Generate
                      </Button>
                      <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }} variant="outlined">
                        Reset
                      </Button>
                    </Paper>
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={8}>
          { apiLoading?        
        <><Box sx={{ p: 4 }} m="auto">
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box></>:
          <Box sx={{ p: 4 }} m="auto" >
            <Paper elevation={3} sx={{ p: 2 }} m="auto">
              <Alert severity="warning" sx={{ mb: 1 }}>Given instructions are valid for one day</Alert>
              { instLoading ?<><Alert severity="info" sx={{ mb: 1 }}>Please select appropriate operating system version and architecture of device</Alert>
                </>:<Box >
                <Stepper orientation="vertical">
                  <Step key="install_cmd" active={true}>
                    <StepLabel >
                      <Typography>
                        Installation Command
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <CopyBlock
                        text={instructions['install_cmd']}
                        language={selectedOSData['id']=='win'?'powershell':'shell'}
                        theme={dracula}
                        showLineNumbers={false}
                        wrapLines
                      />
                    </StepContent>
                  </Step>
                  <Step key="start_cmd" active={true}>
                    <StepLabel >
                      <Typography>
                        Start Command
                      </Typography>
                    </StepLabel>
                    <StepContent>
                    {selectedOSData['id']=='deb' ||selectedOSData['id']=='rpm'? <Paper>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="System Tabs">
                              <Tab label="Systemd" {...a11yProps(0)} />
                              <Tab label="SysV Init" {...a11yProps(1)} />
                            </Tabs>
                          </Box>
                          <TabPanel value={value} index={0}>
                          <CopyBlock

                                text={instructions['start_cmd']['systemd']}
                                language={selectedOSData['id']=='win'?'powershell':'shell'}
                                theme={dracula}
                                showLineNumbers={false}
                                wrapLines
                                />
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                          <CopyBlock

                            text={instructions['start_cmd']['sysV']}
                            language={selectedOSData['id']=='win'?'powershell':'shell'}
                            theme={dracula}
                            showLineNumbers={false}
                            wrapLines
                            />
                          </TabPanel>
                        </Paper>:""}
                    {selectedOSData['id']=='win' ||selectedOSData['id']=='macos'? <Paper>
                  
                          <CopyBlock

                                text={instructions['start_cmd']['cmd']}
                                language={selectedOSData['id']=='win'?'powershell':'shell'}
                                theme={dracula}
                                showLineNumbers={false}
                                wrapLines
                                />
              
                        </Paper>:""}
                    </StepContent>
                  </Step>

                </Stepper>
              </Box>}
              </Paper>
          </Box>}
            </Grid>
          </Grid>
        }

      </Container>
    </>
  );
}
