// ChatBot.js
import React from 'react';
import Chatbot from 'react-chatbot-kit';
import config from './Chatbot/config.js';
import MessageParser from './Chatbot/MessageParser.js';
import ActionProvider from './Chatbot/ActionProvider.js';
import 'react-chatbot-kit/build/main.css';
import './Chatbot/chatbotdesign.css';

const ChatBot = () => {
  return (
    <div>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div>
  );
}

export default ChatBot;