import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { BaseUrl, SupportedPlatform,InstallCmd } from '../constants/constants';
import "react-perfect-scrollbar/dist/css/styles.css";
import axios from 'axios';
import { Container, Stack } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import { getUserDetails } from '../service/UserDetails';
import {useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { getTenantDetails } from '../service/TenantDetails';


export default function Profile() {
  const [user, setUser] = React.useState();
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken
  const [userDetails, setUserDetails] = useState();
  const [tenantDetails, setTenantDetails] = useState();
  const [apiCall, setApiCall] = useState(false);
  
  useEffect(() => {
    setApiCall(true)
    
    getUserDetails()
      .then(resp => {
        setUserDetails(resp);
        setApiCall(false);
      })
   
  }, [])
  useEffect(() => {
    setApiCall(true)
    
    getTenantDetails()
      .then(resp => {
        setTenantDetails(resp);
        setApiCall(false);
      })
   
  }, [])

  return (
    <>
      <Container>
        {apiCall ? <>
        <Box >
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box></> :
        <Paper elevation={6}  sx={{maxWidth:400,m:'auto',padding:'auto'}}>
           
                      <Box sx={{ flexGrow: 1 ,m:2,padding:2}}>
                          <Grid>
                          <Grid container item >
                                  <Grid item xs={6}>
                            
                                  <Typography variant="subtitle2" noWrap>
                                      Name
                                    </Typography></Grid>
                                  <Grid item xs={6}>
                                      {userDetails?.first_name}  {userDetails?.last_name}
                                  </Grid>
                              </Grid>
                              
                              <Grid container item >
                                
                                  <Grid item xs={6}>
                                  <Typography variant="subtitle2" noWrap>
                                      Company Name
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      {userDetails?.company_name}
                                  </Grid>
                              </Grid>
                              <Grid container item >
                                
                                  <Grid item xs={6}>
                                  <Typography variant="subtitle2" noWrap>
                                      Tenant
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      {tenantDetails?.tenant_name}
                                  </Grid>
                              </Grid>
                              <Grid container item >
                                  <Grid item xs={6}>
                                  <Typography variant="subtitle2" noWrap>
                                      Phone
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      {userDetails?.phone}
                                  </Grid>
                              </Grid>
                              <Grid container item >
                                  <Grid item xs={6}>
                                  <Typography variant="subtitle2" noWrap>
                                      Last login
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      {userDetails?.authentication.last_login}
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Box>
        </Paper>
        
        }
   

      </Container>
    </>
  );
}
