// config.js
import { createChatBotMessage } from 'react-chatbot-kit';

const botName = 'Khika Bot';

const config = {
  initialMessages: [createChatBotMessage(`Hello!, How can I help you ?`)],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: 'green',
    },
    chatButton: {
      backgroundColor: 'green',
    },
  },
};

export default config;