import axios from 'axios';
import { useEffect } from 'react';

import swal from 'sweetalert';
import { BaseUrl, ConfirmEmailEndpoint } from '../constants/constants';

const EmailActivation = () => {
  const current_url = window.location.href;      
  const token = current_url.split('email-confirmation/')[1];
  const url = BaseUrl + ConfirmEmailEndpoint + token;
  
  const emailactivation = async () => {
    axios.post(url)
    .then(res=>{
        if(res.status == '200'){
            swal({
                title: "Congratulations, your account is now Active!",
                text: "Go ahead and login",
                icon: "success",
            }).then(function() {
                window.location = "/";
            });
        }
    })
    .catch(function () {
        swal({
            title: "Failed! Email Verification Unsuccessful!",
            text: "Please ensure the copied url is correct",
            icon: "error",
        }).then(function() {
            window.location = "/";
        });
      });
  }

  useEffect(() => {
    emailactivation();
  }, []);
}

export default EmailActivation