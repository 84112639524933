import React, { useState } from 'react';

const Reports_Dashboard = () => {
    const [tenantname, setTenantName] = useState(JSON.parse(window.localStorage.getItem("tenant_name")))
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details.data.accessToken;
    const kibanaToken = user_details.data.kibana_token;
    const srcUrl = `https://saas.khika.in/kibana/app/reports-dashboards?security_tenant=${tenantname}&embed=true&auth_token=${kibanaToken}#/wazuh`;
    
    return (
        <>
        <br />
        <br />
            <iframe 
                className='hide_menu'
                frameBorder="0"
                src={srcUrl}
            />
        </>
    );
}

export default Reports_Dashboard;